<template>
  <div>
    <b-button
      v-if="this.aufgabenKategorie === 'AnlageFlugstamm'"
      class="ml-auto d-flex justify-content-center align-items-center p-2"
      @click="openFilteredFlugstammdatenView"
    >
      Gehe zu Flugstammdaten
    </b-button>
    <h6 class="ml-auto" v-if="aufgabenKategorie === 'GruppeneinkaufPruefen'">
      <a :href="'#/reisetermine/flugplanung?reisetermine=' + joinedReiseterminkuerzel" target="_blank">
        <b-icon icon="box-arrow-up-right"></b-icon>
        Reisetermine in der Flugplanung
      </a>
    </h6>
    <b-table
      :items="reiseterminData"
      :fields="verlinkungenFields"
      responsive
      hover
      style="border: 1px solid rgb(0, 0, 0, 0.1); border-radius: 5px; margin-top: 12px"
      head-variant="light"
      @row-clicked="rowClicked"
    >
      <template v-slot:cell(reiseterminkuerzel)="row">
        <div style="width: 180px; display: flex; flex-direction: row; gap: 0.8rem">
          <div v-if="row.item.ursprungsreiseterminId" style="cursor: pointer">
            <a
              target="_blank"
              :href="'/#/reisetermine/' + row.item.ursprungsreiseterminId"
              v-b-tooltip.hover
              title="Ursprungstermin"
            >
              <i class="far fa-calendar-minus fa-lg"></i>
            </a>
          </div>
          <div v-if="row.item.ersatztermin" style="cursor: pointer">
            <a
              target="_blank"
              :href="'/#/reisetermine/' + row.item.ersatztermin.id"
              v-b-tooltip.hover
              title="Ersatztermin"
            >
              <i class="far fa-calendar-check fa-lg"></i>
            </a>
          </div>

          {{ row.item.reiseterminkuerzel }}
        </div>
      </template>
      <template v-slot:cell(metadata.vkTransferkostenHin)="data">
        <div style="width: 60px">
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(metadata.vkTransferkostenRueck)="data">
        <div style="width: 80px">
          {{ data.value }}
        </div>
      </template>
      <template v-slot:cell(state)="row">
        <b-badge>{{ getStateGerman(row.item.state) }}</b-badge>
      </template>
      <template v-slot:cell(vorgangReleaseprozess.teilnehmerErledigt)="row">
        <div class="position-relative">
          <i
            :class="
              releaseprozessIconsColors(
                'teilnehmerErledigt',
                row.item.vorgangReleaseprozess.teilnehmerErledigt
              )
            "
            class="fas fa-user-check fa-lg"
          ></i>
          <i
            v-if="row.item.vorgangReleaseprozess.configurationFulfilledForAllTeilnehmer === false"
            class="fas fa-asterisk text-danger icon-sm position-absolute translate-middle ml-1"
            title="Passdaten nicht vollständig"
          ></i>
        </div>
      </template>
      <template v-slot:cell(vorgangReleaseprozess.reiseleistungListenversandErledigt)="row">
        <i
          :class="
            releaseprozessIconsColors(
              'reiseleistungListenversandErledigt',
              row.item.vorgangReleaseprozess.reiseleistungListenversandErledigt
            )
          "
          class="fas fa-suitcase fa-lg"
        ></i>
      </template>
      <template v-slot:cell(vorgangReleaseprozess.fluegePassend)="row">
        <i
          :class="
            getFluegePassend(
              row.item.vorgangReleaseprozess.fluegePassend,
              row.item.vorgangReleaseprozess.flugticketsAusgestellt
            )
          "
          class="fas fa-plane fa-lg"
        ></i>
      </template>
      <template v-slot:cell(vorgangReleaseprozess.transferplanungErledigt)="row">
        <i
          :class="
            releaseprozessIconsColors(
              'transferplanungErledigt',
              row.item.vorgangReleaseprozess.transferplanungErledigt
            )
          "
          class="fas fa-car fa-lg"
        ></i>
      </template>
      <template v-slot:cell(reiseunterlagenGeprueft)="row">
        <i
          :class="row.item.reiseunterlagenGeprueft ? 'text-success' : 'text-danger'"
          class="fas fa-list-alt fa-lg"
        ></i>
      </template>
      <template v-slot:cell(transferplanungGeprueft)="row">
        <i
          :class="row.item.transferplanungGeprueft ? 'text-success' : 'text-danger'"
          class="fas fa-bus fa-lg"
        ></i>
      </template>
      <template v-slot:cell(garantiert)="row">
        <CustomIconChecked :value="row.item.garantiert" />
      </template>

      <template v-slot:cell(vorzeitigGarantiert)="row">
        <i
          v-if="row.item.vorzeitigGarantiert"
          :class="vorzeitigGarantiertClass(row.item.vorzeitigGarantiert)"
          class="fa-lg"
        ></i>
        <span v-if="row.item.vorzeitigGarantiert == null">-</span>
      </template>

      <template v-slot:cell(metadata.isKritischeDestination)="row">
        <CustomIconChecked
          v-if="row.item.metadata.isKritischeDestination !== null"
          :value="row.item.metadata.isKritischeDestination"
        />
        <div v-else>-</div>
      </template>
      <template v-slot:cell(metadata.releasefrist)="row">
        <i v-if="row.item.metadata.releasefrist?.length > 1" class="fas fa-check text-success fa-lg"></i>
        <i v-else class="fas fa-times text-danger fa-lg"></i>
      </template>
      <template v-slot:cell(commentCount)="row">
        <span>{{ row.item.comments.length }}</span>
      </template>
      <template v-slot:cell(aufgaben)="row">
        <b-badge :id="'tooltip-target-reisetermin' + row.item.id">
          {{ row.item.aufgaben?.length }}
        </b-badge>
        <b-tooltip
          :target="'tooltip-target-reisetermin' + row.item.id"
          triggers="hover"
          custom-class="custom-tooltip"
          v-if="row.item.aufgaben?.length > 1"
        >
          <AufgabenWidget
            :openAufgabenId="openAufgabenId"
            class="mt-3"
            :ansicht="'aufgabe-details'"
            :aufgaben="row.item.aufgaben || []"
          ></AufgabenWidget>
        </b-tooltip>
      </template>
      <template v-slot:cell(paxWidget)="row">
        <PaxWidget
          class="mb-2"
          :paxObject="row.item.pax"
          :kalkPax="row.item.metadata.kalkPax"
          :erwartetePax="row.item.metadata.erwartetePax"
          :durchfuehrbareMinPax="row.item.metadata.durchfuehrbareMinPax"
        ></PaxWidget>
      </template>
      <template v-slot:cell(reise.prioritaet)="data">
        <b-badge>{{ getValueByPriority(data.value) }}</b-badge>
      </template>
      <template v-slot:cell(zimmerkontingenteWidget)="row">
        <ZimmerkontingenteWidget
          :zimmerkontingente="row.item?.zimmerkontingente"
          :reiseterminId="row.item?.id"
          :isZvc="true"
        ></ZimmerkontingenteWidget>
      </template>
      <template v-slot:cell(nochErwartetePax)="row">
        <span style="font-size: 1rem" :class="`badge badge-${nochErwartetePaxVariant(row.item)}`">
          {{ row.item.nochErwartetePax }}
        </span>
      </template>
      <template v-slot:cell(paxBisMax)="row">
        <span style="font-size: 1rem" :class="`badge badge-${paxBisMaxVariant(row.item)}`">
          {{ row.item.paxBisMax }}
        </span>
      </template>

      <template v-slot:cell(reisezeitraum)="row">
        <div style="width: 150px">
          {{ formatDateWithoutHours(row.item.abreisedatum) }} -
          {{ formatDateWithoutHours(row.item.enddatum) }}
        </div>
      </template>
      <template v-slot:cell(ziellandAbUndZielflughafen)="row">
        {{ row.item.metadata.ziellandAbflughaefen?.map(fh => fh.flughafenIataCode).join(', ') }}
        /
        {{ row.item.metadata.ziellandZielflughaefen?.map(fh => fh.flughafenIataCode).join(', ') }}
      </template>
      <template v-slot:cell(TSHinUndRueck)="row">
        {{ formatTransferSzenario(row.item.metadata.transferszenarioHin) }}
        /
        {{ formatTransferSzenario(row.item.metadata.transferszenarioRueck) }}
      </template>

      <template v-slot:cell(metadata.hotelstartVorLandung)="row">
        <CustomIconChecked :value="row.item.metadata.hotelstartVorLandung" />
      </template>
      <template v-slot:cell(metadata.rueckflugNachHotelende)="row">
        <CustomIconChecked :value="row.item.metadata.rueckflugNachHotelende" />
      </template>
      <template v-slot:cell(abgesagt)="row">
        <CustomIconChecked :value="row.item.abgesagt" />
      </template>
      <template v-slot:cell(transferkostenHinWithCurrency)="row">
        <span>
          {{ row.item.metadata?.transferkostenHin }}
          <span v-if="row.item.metadata?.transferkostenWaehrung">
            {{ getSymbol(row.item.metadata?.transferkostenWaehrung) }}
          </span>
        </span>
      </template>
      <template v-slot:cell(transferkostenRueckWithCurrency)="row">
        <span>
          {{ row.item.metadata?.transferkostenRueck }}
          <span v-if="row.item.metadata?.transferkostenWaehrung">
            {{ getSymbol(row.item.metadata?.transferkostenWaehrung) }}
          </span>
        </span>
      </template>
      <template v-slot:cell(stopSale)="row">
        <CustomIconExclamation :value="row.item.stopSale" />
      </template>
      <template v-slot:cell(inlandsflugEinkauf)="row">
        <i
          v-if="inlandsflugEinkaufState(row.item) === 'skr'"
          class="fas fa-plane text-danger"
          v-b-tooltip.hover
          title="Nur SKR Inlandsflüge"
        ></i>
        <i
          v-if="inlandsflugEinkaufState(row.item) === 'dmc'"
          class="fas fa-plane"
          style="color: #000"
          v-b-tooltip.hover
          title="Nur DMC Inlandsflüge"
        ></i>
        <i
          v-if="inlandsflugEinkaufState(row.item) === 'mixed'"
          class="fas fa-plane"
          style="color: #a6a6a8"
          v-b-tooltip.hover
          title="Gemischte Inlandsflüge"
        ></i>
      </template>
      <template v-slot:cell(flugeinkaufEinkaufsart)="row">
        <span>{{ getEinkaufsartLabel(row.item?.flugeinkauf?.einkaufsart) }}</span>
      </template>
      <template v-slot:cell(flugeinkaufStatusgruppe)="row">
        <span>{{ getStatusgruppeLabel(row.item?.flugeinkauf?.statusgruppe) }}</span>
      </template>
      <template v-slot:cell(flugeinkaufGruppeFlughafen)="row">
        <span>{{ row.item?.flugeinkauf?.gruppeFlughafenIataCode }}</span>
      </template>
      <template v-slot:cell(benoetigteDokumente)="row">
        <span>{{ getRequiredDokumente(row.item?.gueltigeKonfiguration) }}</span>
      </template>
      <template v-slot:cell(flugverfuegbarkeiten)="row">
        <div style="width: 350px; display: flex; justify-content: center">
          <FlugverfuegbarkeitenWidget
            :flugverfuegbarkeiten="row.item.flugverfuegbarkeiten ?? []"
            :data="row.item"
          ></FlugverfuegbarkeitenWidget>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import ZimmerkontingenteWidget from '@/components/common/zimmerkontingente/zimmerkontingente-widget.vue';
import AufgabenWidget from '@/components/produkte/reisetermine/aufgaben-widget.vue';
import PaxWidget from '@/components/produkte/reisetermine/pax-widget.vue';
import FlugverfuegbarkeitenWidget from '@/components/produkte/reisetermine/flugverfuegbarkeiten-widget.vue';

import currenciesJson from '@/core/common/helpers/currencies.json';
import configPriority from '@/core/produkte/reisen/priority.config.json';
import CustomIconChecked from '@/components/common/custom-icon-checked.vue';
import CustomIconExclamation from '@/components/common/custom-icon-exclamation.vue';

import { mapGetters } from 'vuex';

import {
  formatDateWithoutHours,
  einkaufsartOptions,
  statusgruppeOptions,
  releaseprozessIconsColors,
  vorzeitigGarantiertClass,
} from '@/core/common/helpers/utils';

import {
  zvcVerlinkungenFields,
  fomVerlinkungenFields,
  releaseprozessVerlinkungenFields,
  basicVerlinkungenFields,
  fieldsGruppeneinkaufPruefen,
  fieldsAnlageFlugstamm,
  fieldsAnlageHotelstamm,
  fieldsOptionsfristErreicht,
  fieldsStopsaleSetzen,
  teilnehmerdokumenteVerlinkungenFields,
  kurzesTktlFields,
} from '@/core/common/helpers/verlinkungen-config';

export default {
  components: {
    ZimmerkontingenteWidget,
    AufgabenWidget,
    FlugverfuegbarkeitenWidget,
    PaxWidget,
    CustomIconChecked,
    CustomIconExclamation,
  },
  props: {
    reiseterminData: { type: Array, required: true },
    aufgabenKategorie: { type: String, required: true },
    openAufgabenId: { type: Number, default: null },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['getKategorienReleaseprozess']),
    verlinkungenFields() {
      if (this.isFomAufgabe) {
        return fomVerlinkungenFields;
      }

      if (this.isZvcAufgabe) {
        return zvcVerlinkungenFields;
      }

      if (
        this.isReleaseprozessAufgabe ||
        this.isMtzAufgabe ||
        this.aufgabenKategorie == 'FehlendePassdaten' ||
        this.aufgabenKategorie == 'PreTransferlisteAnDomSenden' ||
        this.aufgabenKategorie == 'GarantiePruefen'
      ) {
        return releaseprozessVerlinkungenFields;
      }

      if (this.aufgabenKategorie === 'AnlageHotelstamm' || this.aufgabenKategorie === 'ZusatzterminAnlegen') {
        return fieldsAnlageHotelstamm;
      }

      if (this.aufgabenKategorie === 'AnlageFlugstamm') {
        return fieldsAnlageFlugstamm;
      }

      if (this.aufgabenKategorie === 'GruppeneinkaufPruefen') {
        return fieldsGruppeneinkaufPruefen;
      }

      if (this.aufgabenKategorie === 'OptionsfristErreicht') {
        return fieldsOptionsfristErreicht;
      }

      if (this.aufgabenKategorie === 'StopsaleSetzen') {
        return fieldsStopsaleSetzen;
      }

      if (this.aufgabenKategorie === 'TeilnehmerdokumentePruefen') {
        return teilnehmerdokumenteVerlinkungenFields;
      }

      if (
        this.aufgabenKategorie === 'KurzesTktlFreischalten' ||
        this.aufgabenKategorie === 'KurzesTktlDeaktivieren' ||
        this.aufgabenKategorie === 'ResGeplantErsetzen'
      ) {
        return kurzesTktlFields;
      }
      return basicVerlinkungenFields;
    },
    joinedReiseterminkuerzel() {
      if (!this.reiseterminData) return '';
      return this.reiseterminData.map(rt => rt.reiseterminkuerzel).join(',');
    },
    isReleaseprozessAufgabe() {
      return this.getKategorienReleaseprozess
        .map(kategorie => kategorie.aufgabenKategorie)
        .includes(this.aufgabenKategorie);
    },
    isMtzAufgabe() {
      return this.aufgabenKategorie === 'MtzErreicht' || this.aufgabenKategorie === 'MtzUnterschritten';
    },
    isFomAufgabe() {
      return (
        this.aufgabenKategorie === 'FlugticketsVorzeitigAusstellen' ||
        this.aufgabenKategorie === 'VorabpruefungTicketBeiGruppe' ||
        this.aufgabenKategorie === 'TicketfreigabeYield' ||
        this.aufgabenKategorie === 'TicketRefund' ||
        this.aufgabenKategorie === 'GruppeTicketsAusstellen' ||
        this.aufgabenKategorie === 'SpecialServicesBuchen' ||
        this.aufgabenKategorie === 'NegoQuotieren' ||
        this.aufgabenKategorie === 'IndividualisierungBuchen' ||
        this.aufgabenKategorie === 'Flugcheck'
      );
    },
    jumpIntoFluegeReisetermindetails() {
      return (
        this.aufgabenKategorie === 'FlugticketsVorzeitigAusstellen' ||
        this.aufgabenKategorie === 'VorabpruefungTicketBeiGruppe' ||
        this.aufgabenKategorie === 'TicketfreigabeYield' ||
        this.aufgabenKategorie === 'GruppeTicketsAusstellen' ||
        this.aufgabenKategorie === 'SpecialServicesBuchen' ||
        this.aufgabenKategorie === 'NegoQuotieren' ||
        this.aufgabenKategorie === 'IndividualisierungBuchen' ||
        this.aufgabenKategorie === 'Flugcheck'
      );
    },
    isZvcAufgabe() {
      return (
        this.aufgabenKategorie === 'ZimmerkontingentePruefenMaxPax' ||
        this.aufgabenKategorie === 'ZimmerkontingentePruefenErwartetePax'
      );
    },
  },
  methods: {
    formatDateWithoutHours,
    vorzeitigGarantiertClass,
    releaseprozessIconsColors,
    getRequiredDokumente(gueltigeKonfiguration) {
      if (!gueltigeKonfiguration) return '';

      const fields = {
        versicherungsnachweisRequired: 'Versicherungsnachweis',
        verzichtserklaerungRequired: 'Verzichtserklärung',
        loiAntragRequired: 'LOI Antrag',
        referenznummerAntragRequired: 'Referenznummer',
      };

      return Object.entries(gueltigeKonfiguration)
        .reduce((acc, curr) => {
          const [key, value] = curr;
          if (Object.keys(fields).includes(key) && value == true) {
            acc.push(fields[key]);
          }

          return acc;
        }, [])
        .join(', ');
    },
    getStateGerman(value) {
      return value !== null && value !== undefined ? this.$t('REISETERMINE.states.' + value) : value;
    },
    rowClicked(item) {
      if (this.isReleaseprozessAufgabe || this.isMtzAufgabe) {
        window.open('/#/reisetermine/' + item.id + '/releasepruefung', '_blank');
      } else if (this.jumpIntoFluegeReisetermindetails) {
        window.open(
          '/#/reisetermine/' + item.id + '/?vorgangdetailstab=fluege&scrollToFlugdaten=true',
          '_blank'
        );
      } else {
        window.open('/#/reisetermine/' + item.id, '_blank');
      }
    },
    openFilteredFlugstammdatenView() {
      const multipleReiseterminkuerzel = this.reiseterminData.map(item => item.reiseterminkuerzel);
      window.open(`/#/flugstammdaten?reisetermine=${multipleReiseterminkuerzel}`, '_blank');
    },
    getEinkaufsartLabel(value) {
      return einkaufsartOptions.find(item => item.value === value)?.text;
    },
    getStatusgruppeLabel(value) {
      return statusgruppeOptions.find(item => item.value === value)?.text;
    },
    inlandsflugEinkaufState(reisetermin) {
      if (reisetermin?.metadata?.inlandsfluege) {
        const { inlandsfluege } = reisetermin.metadata;
        if (inlandsfluege.length > 0) {
          if (inlandsfluege.every(inlandsflug => inlandsflug.einkauf === 'Skr')) return 'skr';
          if (inlandsfluege.every(inlandsflug => inlandsflug.einkauf === 'Dmc')) return 'dmc';
          if (
            inlandsfluege.some(inlandsflug => inlandsflug.einkauf === 'Skr') &&
            inlandsfluege.some(inlandsflug => inlandsflug.einkauf === 'Dmc')
          )
            return 'mixed';
        }
      }
      return null;
    },
    getSymbol(code) {
      return currenciesJson[code]?.symbol;
    },
    getValueByPriority(priority) {
      return configPriority[priority].value;
    },
    paxBisMaxVariant(item) {
      const { paxBisMax, zimmerkontingentePersonenFrei } = item;
      return paxBisMax > zimmerkontingentePersonenFrei ? 'warning' : 'secondary';
    },
    nochErwartetePaxVariant(item) {
      const { zimmerkontingentePersonenFrei, nochErwartetePax } = item;
      return nochErwartetePax <= zimmerkontingentePersonenFrei ? 'success' : 'danger';
    },
    getFluegePassend(fluegePassend, flugticketsAusgestellt) {
      return fluegePassend && flugticketsAusgestellt
        ? 'text-success'
        : fluegePassend || flugticketsAusgestellt
        ? 'text-warning'
        : 'text-danger';
    },
    formatTransferSzenario(szenario) {
      if (szenario === 'T5_2' || szenario === 'T5_3') {
        return szenario;
      }
      return szenario?.substring(0, 2);
    },
  },
};
</script>
<style scoped></style>
